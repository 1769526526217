import React from "react";

const SmartOrderIcon = () => {
	return (
		<svg
			width="666px"
			height="129px"
			viewBox="0 0 666 129">
			<title>logo/SmartOrder</title>
			<g
				id="working"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd">
				<g
					id="project--Weston-SmartOrder---desktop"
					transform="translate(-387.000000, -1311.000000)"
					fillRule="nonzero">
					<g
						id="logo/SmartOrder"
						transform="translate(387.000000, 1311.000000)">
						<g id="Group">
							<polygon
								id="Path"
								fill="#FF5E3A"
								points="89.8289874 35.9240506 44.7788004 0 44.7788004 23.4050633 89.8289874 59.6012658"></polygon>
							<polygon
								id="Path"
								fill="#E2472D"
								points="0 35.9240506 44.7788004 0 44.7788004 23.4050633 0 59.6012658"></polygon>
							<polygon
								id="Path"
								fill="#E2472D"
								points="68.3894406 53.3417722 89.8289874 35.9240506 89.8289874 59.6012658 68.3894406 77.0189873"></polygon>
							<polygon
								id="Path"
								fill="#FF5E3A"
								points="0 59.6012658 68.1180539 110.493671 83.3157074 98.5189873 0 35.9240506"></polygon>
							<polygon
								id="Path"
								fill="#FF5E3A"
								points="89.8289874 69.6708861 21.4395468 18.778481 21.4395468 42.1835443 89.8289874 93.0759494"></polygon>
							<polygon
								id="Path"
								fill="#E2472D"
								points="89.8289874 93.0759494 44.7788004 129 44.7788004 105.594937 89.8289874 69.6708861"></polygon>
							<polygon
								id="Path"
								fill="#E2472D"
								points="0 69.6708861 21.4395468 51.9810127 21.4395468 75.6582278 0 93.0759494"></polygon>
							<polygon
								id="Path"
								fill="#FF5E3A"
								points="0 93.0759494 44.7788004 129 44.7788004 105.594937 0 69.6708861"></polygon>
						</g>
						<g
							id="Group"
							transform="translate(111.828422, 0.000000)"
							fill="#002646">
							<g>
								<path
									d="M43.1504804,61.2341772 C43.1504804,58.2405063 42.0649337,55.7911392 39.8938403,54.4303797 C37.722747,52.7974684 33.9233336,51.164557 28.4956002,49.2594937 C23.0678669,47.3544304 18.7256802,45.721519 15.4690401,44.0886076 C6.78466672,39.4620253 2.44248002,32.9303797 2.44248002,24.7658228 C2.44248002,20.6835443 3.5280267,16.8734177 5.97050672,13.6075949 C8.41298674,10.3417722 11.6696268,7.89240506 16.0118135,5.98734177 C20.3540002,4.08227848 25.2389602,3.26582278 30.9380803,3.26582278 C36.3658136,3.26582278 41.2507737,4.35443038 45.5929604,6.25949367 C49.9351471,8.16455696 53.1917871,11.1582278 55.6342671,14.6962025 C58.0767471,18.2341772 59.1622938,22.5886076 59.1622938,27.2151899 L43.1504804,27.2151899 C43.1504804,23.6772152 42.0649337,20.9556962 39.8938403,19.0506329 C37.722747,17.1455696 34.466107,16.056962 30.3953069,16.056962 C26.5958936,16.056962 23.6106402,16.8734177 21.4395468,18.5063291 C19.2684535,20.1392405 18.1829068,22.3164557 18.1829068,25.0379747 C18.1829068,27.4873418 19.5398402,29.664557 21.9823202,31.2974684 C24.4248002,32.9303797 28.2242136,34.5632911 33.1091736,36.1962025 C42.0649337,38.9177215 48.8496004,42.1835443 52.9204004,46.2658228 C56.9912005,50.3481013 59.1622938,55.2468354 59.1622938,61.2341772 C59.1622938,68.0379747 56.7198138,73.2088608 51.5634671,77.0189873 C46.4071204,80.8291139 39.6224537,82.7341772 31.2094669,82.7341772 C25.2389602,82.7341772 19.8112268,81.6455696 14.9262668,79.4683544 C10.0413067,77.2911392 6.24189339,74.2974684 3.79941336,70.4873418 C1.35693334,66.6772152 1.42104895e-14,62.3227848 1.42104895e-14,57.4240506 L16.0118135,57.4240506 C16.0118135,65.8607595 21.1681602,70.2151899 31.2094669,70.2151899 C35.0088803,70.2151899 37.9941336,69.3987342 40.165227,68.0379747 C42.0649337,66.1329114 43.1504804,63.9556962 43.1504804,61.2341772 Z"
									id="Path"></path>
								<path
									d="M83.3157074,23.9493671 L83.8584807,30.4810127 C87.9292807,25.5822785 93.3570141,22.8607595 100.413067,22.8607595 C107.740508,22.8607595 112.896854,25.8544304 115.610721,31.5696203 C119.681521,25.8544304 125.380641,22.8607595 132.708081,22.8607595 C138.949974,22.8607595 143.563548,24.7658228 146.548801,28.3037975 C149.534055,31.8417722 151.162375,37.2848101 151.162375,44.6329114 L151.162375,81.3734177 L135.693334,81.3734177 L135.693334,44.9050633 C135.693334,41.6392405 135.150561,39.1898734 133.793628,37.8291139 C132.436694,36.1962025 130.265601,35.6518987 127.008961,35.6518987 C122.395388,35.6518987 119.138748,37.8291139 117.510428,42.1835443 L117.510428,81.6455696 L102.312774,81.6455696 L102.312774,44.9050633 C102.312774,41.6392405 101.770001,39.1898734 100.413067,37.556962 C99.0561342,35.9240506 96.8850408,35.3797468 93.6284008,35.3797468 C89.2862141,35.3797468 86.029574,37.2848101 84.1298674,40.8227848 L84.1298674,81.3734177 L68.9322139,81.3734177 L68.9322139,23.9493671 L83.3157074,23.9493671 Z"
									id="Path"></path>
								<path
									d="M195.941175,81.6455696 C195.127015,80.2848101 194.855628,78.6518987 194.312855,76.4746835 C190.513442,80.556962 185.899868,82.7341772 179.929361,82.7341772 C174.230241,82.7341772 169.616668,81.1012658 166.088641,77.835443 C162.289228,74.5696203 160.660908,70.4873418 160.660908,65.5886076 C160.660908,59.6012658 162.832001,54.7025316 167.445575,51.4367089 C172.059148,48.1708861 178.572428,46.5379747 186.985415,46.5379747 L194.041468,46.5379747 L194.041468,43.2721519 C194.041468,40.5506329 193.227308,38.3734177 191.870375,37.0126582 C190.513442,35.6518987 188.342348,34.5632911 185.357095,34.5632911 C182.643228,34.5632911 180.743521,35.1075949 179.386588,36.4683544 C178.029655,37.8291139 177.215495,39.4620253 177.215495,41.6392405 L162.017841,41.6392405 C162.017841,38.3734177 163.103388,35.1075949 165.003095,32.3860759 C167.174188,29.664557 169.888055,27.2151899 173.687468,25.5822785 C177.486881,23.9493671 181.557682,23.1329114 186.171255,23.1329114 C193.227308,23.1329114 198.926428,25.0379747 202.997228,28.5759494 C207.068028,32.1139241 209.239122,37.0126582 209.239122,43.5443038 L209.239122,68.5822785 C209.239122,74.0253165 210.053282,78.1075949 211.410215,81.1012658 L211.410215,81.9177215 L195.941175,81.6455696 L195.941175,81.6455696 Z M183.457388,70.7594937 C185.628482,70.7594937 187.799575,70.2151899 189.699282,69.1265823 C191.598988,68.0379747 192.955922,66.6772152 194.041468,65.0443038 L194.041468,55.2468354 L188.342348,55.2468354 C180.743521,55.2468354 176.672721,57.9683544 176.129948,63.1392405 L176.129948,63.9556962 C176.129948,65.8607595 176.672721,67.4936709 178.029655,68.5822785 C179.115201,70.2151899 181.014908,70.7594937 183.457388,70.7594937 Z"
									id="Shape"></path>
								<path
									d="M253.203762,38.3734177 C251.032669,38.1012658 249.404349,37.8291139 247.776029,37.8291139 C242.076909,37.8291139 238.277495,39.7341772 236.377789,43.8164557 L236.377789,81.3734177 L221.180135,81.3734177 L221.180135,23.9493671 L235.563629,23.9493671 L236.106402,30.7531646 C239.091655,25.5822785 243.433842,22.8607595 248.861575,22.8607595 C250.489895,22.8607595 252.118215,23.1329114 253.746535,23.6772152 L253.203762,38.3734177 Z"
									id="Path"></path>
								<path
									d="M281.699362,9.79746835 L281.699362,23.9493671 L291.469282,23.9493671 L291.469282,35.1075949 L281.699362,35.1075949 L281.699362,63.9556962 C281.699362,66.1329114 281.970749,67.4936709 282.784909,68.5822785 C283.599069,69.3987342 285.227389,69.943038 287.398482,69.943038 C289.026802,69.943038 290.655122,69.943038 292.012056,69.6708861 L292.012056,81.3734177 C289.026802,82.1898734 286.041549,82.7341772 282.784909,82.7341772 C271.929442,82.7341772 266.501709,77.2911392 266.230322,66.4050633 L266.230322,35.3797468 L257.817335,35.3797468 L257.817335,24.221519 L266.230322,24.221519 L266.230322,10.0696203 L281.699362,10.0696203 C281.699362,10.0696203 281.699362,9.79746835 281.699362,9.79746835 Z"
									id="Path"></path>
								<path
									d="M360.94427,47.0822785 C360.94427,54.1582278 359.587336,60.4177215 357.144856,65.8607595 C354.702376,71.3037975 351.17435,75.3860759 346.560776,78.3797468 C341.947203,81.3734177 336.790856,82.7341772 330.820349,82.7341772 C321.864589,82.7341772 314.537149,79.4683544 309.109416,72.9367089 C303.681683,66.4050633 300.696429,57.6962025 300.696429,46.8101266 L300.696429,38.9177215 C300.696429,31.8417722 302.053363,25.5822785 304.495843,20.1392405 C306.938323,14.6962025 310.466349,10.6139241 315.079923,7.62025316 C319.693496,4.62658228 324.849843,3.26582278 330.820349,3.26582278 C336.790856,3.26582278 341.947203,4.62658228 346.560776,7.62025316 C351.17435,10.6139241 354.702376,14.4240506 357.144856,19.8670886 C359.587336,25.0379747 360.94427,31.2974684 360.94427,38.1012658 L360.94427,47.0822785 L360.94427,47.0822785 Z M354.43099,38.6455696 C354.43099,29.3924051 352.259896,22.0443038 348.189096,16.8734177 C344.118296,11.7025316 338.147789,8.98101266 330.820349,8.98101266 C323.764296,8.98101266 318.065176,11.7025316 313.722989,16.8734177 C309.380803,22.0443038 307.209709,29.664557 307.209709,39.1898734 L307.209709,47.3544304 C307.209709,56.6075949 309.380803,63.6835443 313.451603,69.1265823 C317.522403,74.5696203 323.492909,77.2911392 330.820349,77.2911392 C338.147789,77.2911392 343.84691,74.5696203 348.189096,69.3987342 C352.259896,64.2278481 354.43099,56.6075949 354.43099,47.3544304 L354.43099,38.6455696 Z"
									id="Shape"></path>
								<path
									d="M402.195043,29.3924051 C400.83811,29.1202532 399.481177,29.1202532 397.852857,29.1202532 C393.782057,29.1202532 390.525417,30.2088608 387.81155,32.3860759 C385.097683,34.5632911 383.197977,37.8291139 381.841043,41.9113924 L381.841043,81.3734177 L375.59915,81.3734177 L375.59915,23.9493671 L381.841043,23.9493671 L381.841043,33.2025316 C385.097683,26.3987342 390.525417,22.8607595 398.124243,22.8607595 C400.02395,22.8607595 401.380883,23.1329114 402.195043,23.6772152 L402.195043,29.3924051 L402.195043,29.3924051 Z"
									id="Path"></path>
								<path
									d="M408.708323,52.2531646 C408.708323,43.2721519 410.60803,36.1962025 414.67883,31.0253165 C418.74963,25.8544304 424.177364,23.1329114 430.96203,23.1329114 C438.832244,23.1329114 444.531364,26.1265823 448.602164,32.3860759 L448.602164,0 L454.844057,0 L454.844057,81.6455696 L448.87355,81.6455696 L448.602164,74.0253165 C444.531364,79.7405063 438.560857,82.7341772 430.690644,82.7341772 C424.177364,82.7341772 418.74963,80.0126582 414.67883,74.8417722 C410.60803,69.3987342 408.708323,62.3227848 408.708323,53.3417722 L408.708323,52.2531646 L408.708323,52.2531646 Z M415.221603,53.3417722 C415.221603,60.6898734 416.578537,66.4050633 419.56379,70.7594937 C422.549044,75.1139241 426.619844,77.0189873 432.047577,77.0189873 C439.91779,77.0189873 445.345524,73.4810127 448.602164,66.6772152 L448.602164,39.7341772 C445.345524,32.3860759 439.91779,28.5759494 432.047577,28.5759494 C426.619844,28.5759494 422.549044,30.7531646 419.56379,34.835443 C416.578537,38.9177215 415.221603,45.1772152 415.221603,53.3417722 Z"
									id="Shape"></path>
								<path
									d="M493.923737,82.4620253 C489.038777,82.4620253 484.696591,81.3734177 480.625791,78.9240506 C476.554991,76.4746835 473.569737,73.2088608 471.398644,68.8544304 C469.227551,64.5 468.142004,59.6012658 468.142004,54.4303797 L468.142004,52.2531646 C468.142004,46.8101266 469.227551,41.6392405 471.398644,37.2848101 C473.569737,32.9303797 476.554991,29.3924051 480.354404,26.943038 C484.153817,24.4936709 488.224617,23.1329114 492.838191,23.1329114 C499.894244,23.1329114 505.321978,25.5822785 509.392778,30.2088608 C513.463578,35.1075949 515.634671,41.3670886 515.634671,49.8037975 L515.634671,53.3417722 L474.655284,53.3417722 L474.655284,54.4303797 C474.655284,60.9620253 476.554991,66.4050633 480.354404,70.7594937 C484.153817,75.1139241 488.767391,77.2911392 494.466511,77.2911392 C497.723151,77.2911392 500.979791,76.7468354 503.422271,75.3860759 C506.136138,74.0253165 508.307231,72.1202532 510.478324,69.3987342 L514.549124,72.3924051 C509.392778,79.1962025 502.608111,82.4620253 493.923737,82.4620253 Z M492.566804,28.3037975 C487.681844,28.3037975 483.882431,29.9367089 480.625791,33.4746835 C477.369151,37.0126582 475.469444,41.6392405 474.655284,47.6265823 L509.121391,47.6265823 L509.121391,46.8101266 C508.850004,41.3670886 507.493071,36.7405063 504.507818,33.2025316 C501.251177,30.2088608 497.451764,28.3037975 492.566804,28.3037975 Z"
									id="Shape"></path>
								<path
									d="M554.171578,29.3924051 C552.814645,29.1202532 551.457711,29.1202532 549.829391,29.1202532 C545.758591,29.1202532 542.501951,30.2088608 539.788084,32.3860759 C537.074218,34.5632911 535.174511,37.8291139 533.817578,41.9113924 L533.817578,81.3734177 L527.575684,81.3734177 L527.575684,23.9493671 L533.817578,23.9493671 L533.817578,33.2025316 C537.074218,26.3987342 542.501951,22.8607595 550.100778,22.8607595 C552.000485,22.8607595 553.357418,23.1329114 554.171578,23.6772152 L554.171578,29.3924051 L554.171578,29.3924051 Z"
									id="Path"></path>
							</g>
							<g transform="translate(0.000000, 104.778481)">
								<polygon
									id="Path"
									points="6.51328005 16.6012658 7.05605339 19.5949367 7.59882673 16.8734177 12.2124001 0.544303797 14.6548801 0.544303797 18.9970668 16.8734177 19.5398402 19.5949367 20.0826135 16.6012658 23.6106402 0.816455696 27.1386669 0.816455696 21.4395468 23.9493671 18.7256802 23.9493671 13.8407201 7.07594937 13.5693334 5.17088608 13.2979468 7.07594937 8.41298674 23.9493671 5.69912005 23.9493671 -1.42104895e-14 0.816455696 2.98525336 0.816455696"></polygon>
								<polygon
									id="Path"
									points="49.9351471 13.0632911 39.8938403 13.0632911 39.8938403 21.2278481 51.5634671 21.2278481 51.5634671 23.6772152 36.908587 23.6772152 36.908587 0.544303797 51.5634671 0.544303797 51.5634671 2.99367089 40.165227 2.99367089 40.165227 10.3417722 50.2065337 10.3417722"></polygon>
								<path
									d="M68.9322139,13.335443 C66.2183472,12.5189873 64.3186405,11.7025316 63.2330939,10.6139241 C62.1475472,9.52531646 61.3333872,8.16455696 61.3333872,6.53164557 C61.3333872,4.62658228 62.1475472,3.26582278 63.5044805,1.90506329 C64.8614139,0.544303797 66.7611206,0 69.2036006,0 C70.8319206,0 72.1888539,0.272151899 73.5457873,0.816455696 C74.9027206,1.36075949 75.7168806,2.17721519 76.5310406,3.26582278 C77.3452006,4.35443038 77.6165873,5.44303797 77.6165873,6.80379747 L74.631334,6.80379747 C74.631334,5.44303797 74.0885606,4.35443038 73.2744006,3.53797468 C72.4602406,2.72151899 71.1033073,2.44936709 69.4749872,2.44936709 C67.8466672,2.44936709 66.7611206,2.72151899 65.9469605,3.53797468 C65.1328005,4.08227848 64.5900272,5.17088608 64.5900272,6.25949367 C64.5900272,7.07594937 64.8614139,7.89240506 65.6755739,8.70886076 C66.4897339,9.25316456 67.8466672,10.0696203 69.7463739,10.6139241 C71.6460806,11.1582278 73.2744006,11.7025316 74.3599473,12.5189873 C75.445494,13.335443 76.259654,13.8797468 76.8024273,14.6962025 C77.3452006,15.5126582 77.6165873,16.6012658 77.6165873,17.6898734 C77.6165873,19.5949367 76.8024273,20.9556962 75.445494,22.3164557 C74.0885606,23.6772152 71.9174673,23.9493671 69.4749872,23.9493671 C67.8466672,23.9493671 66.4897339,23.6772152 65.1328005,23.1329114 C63.7758672,22.5886076 62.6903205,21.7721519 61.8761605,20.6835443 C61.0620005,19.5949367 60.7906138,18.5063291 60.7906138,17.1455696 L63.7758672,17.1455696 C63.7758672,18.5063291 64.3186405,19.5949367 65.4041872,20.4113924 C66.4897339,21.2278481 67.8466672,21.5 69.4749872,21.5 C71.1033073,21.5 72.1888539,21.2278481 73.2744006,20.4113924 C74.3599473,19.5949367 74.631334,18.778481 74.631334,17.6898734 C74.631334,16.6012658 74.3599473,15.7848101 73.5457873,14.9683544 C72.7316273,14.1518987 70.8319206,14.1518987 68.9322139,13.335443 Z"
									id="Path"></path>
								<polygon
									id="Path"
									points="103.941094 2.99367089 96.6136541 2.99367089 96.6136541 23.6772152 93.6284008 23.6772152 93.6284008 2.99367089 86.3009607 2.99367089 86.3009607 0.544303797 104.212481 0.544303797 104.212481 2.99367089"></polygon>
								<path
									d="M131.079761,13.0632911 C131.079761,15.2405063 130.808374,17.4177215 129.994214,19.0506329 C129.180054,20.6835443 128.094508,22.0443038 126.737574,22.8607595 C125.380641,23.6772152 123.752321,24.221519 121.852614,24.221519 C119.952908,24.221519 118.324588,23.6772152 116.967654,22.8607595 C115.610721,22.0443038 114.525174,20.6835443 113.711014,19.0506329 C112.896854,17.4177215 112.625468,15.5126582 112.625468,13.335443 L112.625468,11.7025316 C112.625468,9.52531646 112.896854,7.62025316 113.711014,5.71518987 C114.525174,3.81012658 115.610721,2.72151899 116.967654,1.90506329 C118.324588,1.08860759 119.952908,0.544303797 121.852614,0.544303797 C123.752321,0.544303797 125.380641,1.08860759 126.737574,1.90506329 C128.094508,2.72151899 129.180054,4.08227848 129.994214,5.71518987 C130.808374,7.34810127 131.079761,9.52531646 131.079761,11.7025316 L131.079761,13.0632911 Z M128.094508,11.4303797 C128.094508,8.70886076 127.551734,6.53164557 126.466188,5.17088608 C125.380641,3.81012658 123.752321,2.99367089 121.852614,2.99367089 C119.952908,2.99367089 118.324588,3.81012658 117.239041,5.17088608 C116.153494,6.53164557 115.610721,8.70886076 115.610721,11.4303797 L115.610721,13.0632911 C115.610721,15.7848101 116.153494,17.9620253 117.239041,19.3227848 C118.324588,20.6835443 119.952908,21.5 121.852614,21.5 C123.752321,21.5 125.380641,20.6835443 126.466188,19.3227848 C127.551734,17.9620253 128.094508,15.7848101 128.094508,13.0632911 L128.094508,11.4303797 Z"
									id="Shape"></path>
								<polygon
									id="Path"
									points="160.118135 23.6772152 157.132881 23.6772152 145.463255 5.71518987 145.463255 23.6772152 142.478001 23.6772152 142.478001 0.544303797 145.463255 0.544303797 157.132881 18.5063291 157.132881 0.544303797 160.118135 0.544303797"></polygon>
								<polygon
									id="Path"
									points="199.197815 13.6075949 189.427895 13.6075949 189.427895 23.9493671 186.442642 23.9493671 186.442642 0.816455696 200.826135 0.816455696 200.826135 3.26582278 189.427895 3.26582278 189.427895 11.1582278 199.197815 11.1582278"></polygon>
								<path
									d="M228.778962,13.0632911 C228.778962,15.2405063 228.507575,17.4177215 227.693415,19.0506329 C226.879255,20.6835443 225.793709,22.0443038 224.436775,22.8607595 C223.079842,23.6772152 221.451522,24.221519 219.551815,24.221519 C217.652108,24.221519 216.023788,23.6772152 214.666855,22.8607595 C213.309922,22.0443038 212.224375,20.6835443 211.410215,19.0506329 C210.596055,17.4177215 210.324668,15.5126582 210.324668,13.335443 L210.324668,11.7025316 C210.324668,9.52531646 210.596055,7.62025316 211.410215,5.71518987 C212.224375,3.81012658 213.309922,2.72151899 214.666855,1.90506329 C216.023788,1.08860759 217.652108,0.544303797 219.551815,0.544303797 C221.451522,0.544303797 223.079842,1.08860759 224.436775,1.90506329 C225.793709,2.72151899 226.879255,4.08227848 227.693415,5.71518987 C228.507575,7.34810127 228.778962,9.52531646 228.778962,11.7025316 L228.778962,13.0632911 Z M225.793709,11.4303797 C225.793709,8.70886076 225.250935,6.53164557 224.165389,5.17088608 C223.079842,3.81012658 221.451522,2.99367089 219.551815,2.99367089 C217.652108,2.99367089 216.023788,3.81012658 214.938242,5.17088608 C213.852695,6.53164557 213.309922,8.70886076 213.309922,11.4303797 L213.309922,13.0632911 C213.309922,15.7848101 213.852695,17.9620253 214.938242,19.3227848 C216.023788,20.6835443 217.652108,21.5 219.551815,21.5 C221.451522,21.5 223.079842,20.6835443 224.165389,19.3227848 C225.250935,17.9620253 225.793709,15.7848101 225.793709,13.0632911 L225.793709,11.4303797 Z"
									id="Shape"></path>
								<path
									d="M257.817335,13.0632911 C257.817335,15.2405063 257.545949,17.4177215 256.731789,19.0506329 C255.917629,20.6835443 254.832082,22.0443038 253.475149,22.8607595 C252.118215,23.6772152 250.489895,24.221519 248.590189,24.221519 C246.690482,24.221519 245.062162,23.6772152 243.705229,22.8607595 C242.348295,22.0443038 241.262749,20.6835443 240.448589,19.0506329 C239.634429,17.4177215 239.363042,15.5126582 239.363042,13.335443 L239.363042,11.7025316 C239.363042,9.52531646 239.634429,7.62025316 240.448589,5.71518987 C241.262749,3.81012658 242.348295,2.72151899 243.705229,1.90506329 C245.062162,1.08860759 246.690482,0.544303797 248.590189,0.544303797 C250.489895,0.544303797 252.118215,1.08860759 253.475149,1.90506329 C254.832082,2.72151899 255.917629,4.08227848 256.731789,5.71518987 C257.545949,7.34810127 257.817335,9.52531646 257.817335,11.7025316 L257.817335,13.0632911 Z M254.832082,11.4303797 C254.832082,8.70886076 254.289309,6.53164557 253.203762,5.17088608 C252.118215,3.81012658 250.489895,2.99367089 248.590189,2.99367089 C246.690482,2.99367089 245.062162,3.81012658 243.976615,5.17088608 C242.891069,6.53164557 242.348295,8.70886076 242.348295,11.4303797 L242.348295,13.0632911 C242.348295,15.7848101 242.891069,17.9620253 243.976615,19.3227848 C245.062162,20.6835443 246.690482,21.5 248.590189,21.5 C250.489895,21.5 252.118215,20.6835443 253.203762,19.3227848 C254.289309,17.9620253 254.832082,15.7848101 254.832082,13.0632911 L254.832082,11.4303797 Z"
									id="Shape"></path>
								<path
									d="M268.944189,23.6772152 L268.944189,0.544303797 L275.457469,0.544303797 C277.357176,0.544303797 279.256882,1.08860759 280.885202,1.90506329 C282.513522,2.72151899 283.599069,4.08227848 284.413229,5.71518987 C285.227389,7.34810127 285.770162,9.25316456 285.770162,11.4303797 L285.770162,12.7911392 C285.770162,14.9683544 285.227389,16.8734177 284.413229,18.5063291 C283.599069,20.1392405 282.242136,21.5 280.885202,22.3164557 C279.528269,23.1329114 277.628562,23.6772152 275.457469,23.6772152 L268.944189,23.6772152 Z M271.929442,2.99367089 L271.929442,21.2278481 L275.186082,21.2278481 C277.628562,21.2278481 279.256882,20.4113924 280.613816,19.0506329 C281.970749,17.6898734 282.513522,15.5126582 282.513522,12.7911392 L282.513522,11.4303797 C282.513522,8.70886076 281.970749,6.80379747 280.613816,5.17088608 C279.256882,3.81012658 277.628562,2.99367089 275.457469,2.99367089 L271.929442,2.99367089 L271.929442,2.99367089 Z"
									id="Shape"></path>
								<path
									d="M303.681683,13.335443 C300.967816,12.5189873 299.068109,11.7025316 297.982562,10.6139241 C296.897016,9.52531646 296.082856,8.16455696 296.082856,6.53164557 C296.082856,4.62658228 296.897016,3.26582278 298.253949,1.90506329 C299.610882,0.544303797 301.510589,0 303.953069,0 C305.581389,0 306.938323,0.272151899 308.295256,0.816455696 C309.652189,1.36075949 310.466349,2.17721519 311.280509,3.26582278 C312.094669,4.35443038 312.366056,5.44303797 312.366056,6.80379747 L309.380803,6.80379747 C309.380803,5.44303797 308.838029,4.35443038 308.023869,3.53797468 C307.209709,2.72151899 305.852776,2.44936709 304.224456,2.44936709 C302.596136,2.44936709 301.510589,2.72151899 300.696429,3.53797468 C299.882269,4.08227848 299.339496,5.17088608 299.339496,6.25949367 C299.339496,7.07594937 299.610882,7.89240506 300.425042,8.70886076 C301.239202,9.25316456 302.596136,10.0696203 304.495843,10.6139241 C306.395549,11.1582278 308.023869,11.7025316 309.109416,12.5189873 C310.194963,13.335443 311.009123,13.8797468 311.551896,14.6962025 C312.094669,15.5126582 312.366056,16.6012658 312.366056,17.6898734 C312.366056,19.5949367 311.551896,20.9556962 310.194963,22.3164557 C308.838029,23.6772152 306.666936,23.9493671 304.224456,23.9493671 C302.596136,23.9493671 301.239202,23.6772152 299.882269,23.1329114 C298.525336,22.5886076 297.439789,21.7721519 296.625629,20.6835443 C295.811469,19.5949367 295.540082,18.5063291 295.540082,17.1455696 L298.525336,17.1455696 C298.525336,18.5063291 299.068109,19.5949367 300.153656,20.4113924 C301.239202,21.2278481 302.596136,21.5 304.224456,21.5 C305.852776,21.5 306.938323,21.2278481 308.023869,20.4113924 C309.109416,19.5949367 309.380803,18.778481 309.380803,17.6898734 C309.380803,16.6012658 309.109416,15.7848101 308.295256,14.9683544 C307.481096,14.1518987 305.581389,14.1518987 303.681683,13.335443 Z"
									id="Path"></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default SmartOrderIcon;
