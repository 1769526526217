import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { BubblyContainer } from 'react-bubbly-transitions';
import Project from './pages/Project';
import ScrollToTop from './hooks/useScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <React.StrictMode>
    <ScrollToTop/>
    <BubblyContainer/>
    <Routes>
      <Route path='/' element={<App/>}/>
      <Route path='/:project' element={<Project/>}/>
    </Routes>
  </React.StrictMode>
  </BrowserRouter>
);
