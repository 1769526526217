import styled, { ThemeProvider } from "styled-components";
import Nav from "./components/Nav";
import Hero from "./components/home/Hero";
import Projects from "./components/home/Projects";
import { useRef, useState, useEffect } from "react";
import About from "./components/home/About";
import Clients from "./components/home/Clients";
import Connect from "./components/Connect";
import { useLocation } from "react-router";
import theme from "./styles/theme";
import GlobalStyle from "./styles/GlobalStyle";

const StyledMain = styled.main``;

function App() {
	const location = useLocation();
	const projectsRef = useRef(null);

	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		  const handleResizeWindow = () => setWidth(window.innerWidth);
		  window.addEventListener("resize", handleResizeWindow);
		  return () => {
			  window.removeEventListener("resize", handleResizeWindow);
		  };
	  }, []);

	return (
    <>
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<StyledMain className="App">
				<Nav location={location} />
				<Hero projectsRef={projectsRef} />
				<Projects projectsRef={projectsRef} />
				<About/>
				<Clients/>
				<Connect location={location}/>
			</StyledMain>
		</ThemeProvider>
    </>
	);
}

export default App;
