import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import sr from '../../utils/sr'
import { srConfig } from "../../config";

const StyledSection = styled.section`
background-color: var(--violet);
color: var(--white);
padding: 80px 8.33333%;
position: relative;

.container {
	padding-left: 16px;
	h1 {
		max-width: 62.5%;
	}
	p {
		max-width: 47.33333%;
		line-height: 40px;
		&:first-of-type {
			padding: 40px 0;
		}
	}
}
@media (max-width: 769px) {
	.container p {
		max-width: 100%;
	}
}

@media (max-width: 500px) {
	padding: 80px 40px;
	.container {
		padding-left: 0;
	}
}
`;
const StyledBackground = styled.div`
${({theme}) => theme.mixins.background};

@media (max-width: 900px) {
    div {
        &:nth-of-type(4), &:last-of-type {
            display: none;
        }
    }
}`;

const About = () => {
	const revealContainer = useRef(null)

	useEffect(() => {
		sr.reveal(revealContainer.current, srConfig());
	}, []);


	return (
		<StyledSection id="about">
			<StyledBackground>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</StyledBackground>
			<div className="container" ref={revealContainer}>
				<h1>
					Product&nbsp;Designer/<br/>Consultant/<br/>Illustrator/<br/>Experience&nbsp;Strategist
				</h1>
				<p className="top">
					with experience in large agencies, <u>boutique design companies</u>,
					and in house at a great <u>Canadian brand</u>.
				</p>
				<p className="bottom">
					My work is focused on mobile first, with a deep understanding in how
					to design cross-platform accessible experiences that centre around the
					needs of users. I create great work that focuses on solving problems
					and fostering meaningful relationships between brands and their
					clients.
				</p>
			</div>
		</StyledSection>
	);
};

export default About;
