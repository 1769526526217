import React, { useState, useEffect } from "react";
import useScrollDirection from "../hooks/useSrollDirection";
import styled, { css } from "styled-components";
import { BubblyLink as Link } from "react-bubbly-transitions";
import { navLinks } from "../config";
import { HashLink } from "react-router-hash-link";
import Icon from "./Icons/Icon";

const StyledHeader = styled.header`
	${({ theme }) => theme.mixins.flexBetween};
	position: fixed;
	top: 0;
	z-index: 11;
	padding: 0 32px;
	width: 100%;
	height: var(--nav-height);
	pointer-events: auto !important;
	user-select: auto !important;
	transition: var(--nav-transition);

	${(props) =>
		props.scrollDirection === "up" &&
		!props.scrolledToTop &&
		css`
			height: var(--nav-height);
			transform: translateY(0px);
		`};

	${(props) =>
		props.scrollDirection === "down" &&
		!props.scrolledToTop &&
		css`
			height: var(--nav-height);
			transform: translateY(calc(var(--nav-height) * -1));
		`};

		a svg {
			animation: fadein 0.4s linear;
			// animation-iteration-count: 1;
		}
`;

const StyledNav = styled.nav`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	width: 100%;
	counter-reset: item 0;
	z-index: 12;
	padding-top: 32px;
	a,
	button {
		transition: var(--nav-transition);
		color: var(--white);
		text-decoration: none;
		font-size: 18px;
		line-height: 21.15px;
		font-weight: 500;
	}

	${(props) =>
		props.dark &&
		css`
			a,
			button {
				color: #000;
			}
		`}
`;

const StyledLinks = styled.div`
	${({ theme }) => theme.mixins.flexCenter};
	gap: 64px;
	position: relative;

	button {
		padding: 0;
	}

	a:first-of-type {
		animation: fadeDown 0.4s linear;
	}
	a:last-of-type {
		animation: fadeDown-delay-1 0.6s linear;
	}

	a,
	button {
		aniamtion-iteration-count: 1;
		transform: translate(0, 0);
		&::after {
			content: "";
			width: 0;
			height: 1px;
			position: absolute;
			background-color: var(--white);
			bottom: -1px;
			left: 0;
			transition: var(--nav-transition);
		}
		&:hover::after {
			width: 100%;
		}
	}

	@media (max-width: 769px) {
		.nav-about,
		button {
			display: none;
		}
	}

	${(props) =>
		props.dark &&
		css`
			a,
			button {
				&::after {
					background-color: #000;
				}
			}
		`}

`;

const Nav = ({ location }) => {
	const isHome = location.pathname === "/";
	const scrollDirection = useScrollDirection();
	const [scrolledToTop, setScrolledToTop] = useState(true);
	const [animate, setAnimate] = useState(false)

	const handleScroll = () => {
		setScrolledToTop(window.pageYOffset < 50);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		if (location.hash) {
			const id = location.hash.substring(1);
			setTimeout(() => {
				const el = document.getElementById(id);
				if (el) {
					el.scrollIntoView({ behavior: "smooth" });
					el.focus();
				}
			}, 1000);
		}
	}, [location.hash]);

	const Logo = (
		<div className='logo' tabIndex="-1">
			{isHome ? (
				<a href="/" aria-label="home">
					<Icon name="logo" />
				</a>
			) : (
				<Link to="/" aria-label="home" colorStart="#FFFFFF" colorEnd="#1192E8">
					<Icon name="logo" />
				</Link>
			)}
		</div>
	);

	return (
		<StyledHeader
			scrollDirection={scrollDirection}
			scrolledToTop={scrolledToTop}>
			<StyledNav dark={!isHome && window.scrollY > 550 ? true : false}>
				<>{Logo}</>
				<StyledLinks dark={!isHome && window.scrollY > 550 ? true : false}>
					{isHome ? (
						navLinks?.map(({ url, name }, i) => (
							<HashLink
								key={i}
								to={url}
								className={`nav-${url.substring(2)}`}>
								{name}
							</HashLink>
						))
					) : (
						<>
							<Link
								to="/#about"
								id="nav-about"
								colorStart="#16161F">
								About
							</Link>
							<HashLink
								to={`${location.pathname}#connect`}>
								Let's Talk
							</HashLink>
						</>
					)}
				</StyledLinks>
			</StyledNav>
		</StyledHeader>
	);
};

export default Nav;
