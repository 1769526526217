import React from "react";

const ArrowIcon = () => {
	return (
		<svg  viewBox="10.65 4.9 26.7 38" height='24' width='24'>
			<path d="M24 42.9 10.65 29.6l1.6-1.6 10.6 10.65V4.9h2.3v33.75L35.75 28l1.6 1.6Z" />
		</svg>
	);
};

export default ArrowIcon;
