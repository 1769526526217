import React from "react";
import styled from "styled-components";

import Icon from "../Icons/Icon";

const StyledSection = styled.section`
	position: relative;
	background-color: var(--light-blue);
	padding: 80px 8.33333%;
	.container {
		z-index: 2;
		position: relative;

        h1 {
            padding-left: 16px;
        }

		div.imgs {
			padding-top: 60px;
			display: grid;
			grid-template-rows: 1fr 1fr;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			align-items: center;
			justify-items: center;
			row-gap: 60px;
		}
	}

	@media (max-width: 900px) {
		.container {
			div.imgs {
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 1fr 1fr 1fr 1fr;
			}
		}
	}

    @media (max-width: 500px) {
        padding: 80px 40px;
        .container {
            h1 {
                padding-left: 0;
            }
            div.imgs {
                svg {
                    scale: 0.625;
                    width: 100%;
                }
            }
        }
    }
`;
const StyledBackground = styled.div`
	${({ theme }) => theme.mixins.background};

	div {
		background: rgba(221, 225, 230, 0.3);
	}

	@media (max-width: 900px) {
		div {
			&:nth-of-type(4),
			&:last-of-type {
				display: none;
			}
		}
	}
`;

const clients = [
	"ac-logo",
	"can-logo",
	"IBM",
	"RBC",
	"roots",
	"weston",
	"tangerine",
	"TML",
];

const Clients = () => {
	return (
		<StyledSection>
			<StyledBackground>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</StyledBackground>
			<div className="container">
				<h1>Select Clients</h1>
				<div className="imgs">
					{clients?.map((client, i) => (
						<Icon key={i} name={client} />
					))}
				</div>
			</div>
		</StyledSection>
	);
};

export default Clients;
