import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import Nav from '../components/Nav'
import styled, { ThemeProvider } from 'styled-components'
import Connect from '../components/Connect'
import ProjectHero from '../components/project/ProjectHero'
import { aeroplan, weston, parks, roots } from '../config'
import ProjectInfo from '../components/project/ProjectInfo'
import ProjectNext from '../components/project/ProjectNext'
import GlobalStyle from '../styles/GlobalStyle'
import theme from '../styles/theme'

const StyledMain = styled.main`


`

const Project = () => {
  const location = useLocation()
  const [activeProject, setActiveProject] = useState()
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleResizeWindow);
		return () => {
			window.removeEventListener("resize", handleResizeWindow);
		};
	}, []);


  useEffect(() => {
    if (location.pathname === '/aeroplan') {
      setActiveProject(aeroplan)
    } if (location.pathname === '/weston') {
      setActiveProject(weston)
    } if (location.pathname === '/parks') {
      setActiveProject(parks)
    } if (location.pathname === '/roots') {
      setActiveProject(roots)
    }
  }, [location.pathname])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle/>
    <StyledMain>
        <Nav location={location}/>
        <ProjectHero activeProject={activeProject} width={width}/>
        <ProjectInfo activeProject={activeProject} width={width}/>
        <ProjectNext activeProject={activeProject} width={width}/>
        <Connect location={location}/>
    </StyledMain>
    </ThemeProvider>
  )
}

export default Project