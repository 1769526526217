import React from "react";
import styled from "styled-components";

const StyledSection = styled.section`

// background-color: var(--light-blue);
	img {
        position: absolute;
        top: 0;
        left: 0;
		width: 100vw;
	}
    .gradient {
        position: absolute;
        top: 0;
        left: 0;
        height: 226px;
        width: 100%;
        background: linear-gradient(rgba(22, 22, 31, 0.5), rgba(22, 22, 31, 0));
        z-index: 2;
    }
.AC-hero, .WF-hero, .PC-hero, .RO-hero {
    animation: fadein 1s linear;
}
.AC-bg, .WF-bg, .PC-bg, .RO-bg {
    top: 0;
    left: 0;
    width: 100vw;
    height: 38.194vw;
    z-index: -1;
}
.AC-bg {
    background-color: var(--black);
}
.WF-bg {
    background-color: #F1631B;
}
.PC-bg {
    background-color: #0D332C;
}
.RO-bg {
    background-color: #737679;
}

@media (max-width: 414px) {
    .AC-bg, .WF-bg, .PC-bg, .RO-bg {
        height: 80vw;
    }
}
`;

const StyledBackground = styled.div`
${({theme}) => theme.mixins.background};
div {
    background: rgba(221, 225, 230, 0.3);
}
`

const StyledInfo = styled.div`
position: relative;
padding: 40px 8.3333%;
background-color: var(--light-blue);

animation: fadein 1s linear;

.title {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;

    h2 {
        display: flex;
        flex-direction: column;
    }
}
ul {
    list-style: none;
    margin: 0;
    padding: 32px 16px 0 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

@media (max-width: 900px) {
    .title {
        flex-direction: column;
        justify-content: center;
        gap: 24px;
    }
}
@media (max-width: 414px) {
    padding: 40px;
    
    .title {
        padding: 0;
    }
    ul {
        padding: 32px 0 0 0;
    }
}

`

const ProjectHero = ({ activeProject, width }) => {

	return (
		<StyledSection>
			{activeProject ? (
				<>
                <div className={activeProject.id + '-bg'}></div>
                <div className="gradient"></div>
					<img
                    className={activeProject.id + '-hero'}
						src={
							process.env.PUBLIC_URL +
							`assets/${activeProject.id}/${width > 414 ? activeProject.cover : activeProject.coverm}`
						}
						alt={`${activeProject.client} hero`}
					/>
					<StyledInfo>
                        <StyledBackground>
                            <div></div>
                            <div></div>
                            <div></div>
                            {width > 414 ? <><div></div>
                            <div></div></> : ''}
                        </StyledBackground>
						<div className="title">
							<h1>{activeProject.title}</h1>
							<h2>{width > 500 ? <><span>{activeProject.subtitle1}</span><span>{activeProject.subtitle2}</span></> : <>{activeProject.subtitle1}{' '}{activeProject.subtitle2}</>}</h2>
						</div>
						<ul>
							<li>
								<p className="bold">Client</p>
								<p>{activeProject.client}</p>
							</li>
							<li>
								<p className="bold">Role</p>
								<p>{activeProject.role}</p>
							</li>
						</ul>
					</StyledInfo>
				</>
			) : (
				""
			)}
		</StyledSection>
	);
};

export default ProjectHero;
