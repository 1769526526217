module.exports = {
	email: "liam@liamlawlordesign.com",

	socialMedia: [
		{
			name: "Linkedin",
			url: "https://www.linkedin.com/in/liam-lawlor-79aa33a3",
		},
		{
			name: "Email",
			url: "mailto:liam@liamlawlordesign.com",
		},
		{
			name: "Instagram",
			url: "https://www.instagram.com/liamlawlor/?hl=en",
		},
	],

	navLinks: [
		{
			name: "About",
			url: "/#about",
		},
		{
			name: "Let's Talk",
			url: "/#connect",
		},
	],
	aeroplan: {
		id: "AC",
		cover: "AC-landing-hero@2x.jpg",
		coverm: "AC-landing-hero-m@2x.jpg",
		title: "Air Canada Aeroplan",
		subtitle1: "Product Design for Aeroplan, a new",
		subtitle2: "everyday loyalty program",
		client: "Air Canada Aeroplan",
		role: "Lead Product Designer",
		midTxt: [
			{
				title: "The Dashboard",
				subtitle: "Crafting a new loyalty experience",
				txt: "In 2019, Air Canada began the creation of its new Aeroplan loyalty program, with a dynamic Loyalty Dashboard that helped users see,understand and use their status, benefits and everyday earning opportunities. I took on the role of defining the new visual style for Aeroplan's digital presence, including understanding how to combine two existing loyalty programs.",
			},
			{
				title: "A mobile experience",
				subtitle: "For Flyers on the go",
				txt: "Our dashboard was built mobile first, offering the same complex and dynamic content to users when they were away from home, travelling to earn their status. We wanted to provide quick, intiuitive options to users based on their status and location, including ensuring their card was a tap away.",
			},
			{
				title: "Aeroplan Card",
				subtitle: "Creating the digital card",
				txt: "The Aeroplan card was the showpeice of the new Loyalty Dashboard, and as such, needed to be simple, while providing dynamic information based on a user's persona. Evolving significantly over the design process, the card's end result is a perfect encapsulation of the Aeroplan digital experience.",
			},
			{ title: "Aeroplan Card", subtitle: "Final Card Suite" },
			{ title: "Aeroplan Status", subtitle: "Tracking your loyalty status" },
		],
		order: [
			0,
			["01.jpg"],
			["02.png", "03.png"],
			["04.jpg"],
			["05.jpg"],
			1,
			["06.jpg", "07.jpg"],
			2,
			["08.jpg"],
			3,
			["09.jpg"],
			4,
			["10.png", "11.png"],
			["12.png", "13.png", "14.png"],
		],
		url: "/weston",
	},
	weston: {
		id: "WF",
		cover: "WF-landing-hero@2x.jpg",
		coverm: "WF-landing-hero-m@2x.jpg",
		title: "Weston SmartOrder",
		subtitle1: "Baking a powerful, new SmartOrder bread",
		subtitle2: "order management experience",
		client: "Weston Foods",
		role: "Lead Product Designer - Mobile App",
		midTxt: [
			{
				title: "Smart Order Management",
				subtitle: "Putting delivery drivers & partners on track",
				txt: "Weston Foods is one of two major bread and bakery companies within Canada, utilizing a system of +700 independent contractors to order and deliver bread nationwide. SmartOrder is Weston's new, digital order management system designed to enable faster online ordering, ease of traceability, and integration with cognitive computing to provide real, actionable insights",
			},
			{
				title: "Design System",
				subtitle: "Baking a brand new component library",
			},
			{ title: "SmartOrder", subtitle: "Bread-y to go - Order Dashboard" },
			{ title: "Change Management", subtitle: "App & Program Rollout" },
		],
		order: [
			0,
			"smartorder",
			["01.jpg"],
			1,
			["02.jpg"],
			["03.jpg"],
			2,
			["04.jpg"],
			["05.jpg", "06.jpg", "07.jpg"],
			3,
			["08.jpg"],
			["09.jpg", "10.jpg"],
			["11.jpg"],
			["12.jpg", "13.jpg"],
		],
		url: "/parks",
	},
	parks: {
		id: "PC",
		cover: "PC-landing-hero@2x.jpg",
		coverm: "Parks-hero-m@2x.jpg",
		title: "Parks Canada",
		subtitle1: "An enhanced escape to the great outdoors,",
		subtitle2: "embracing Canadian heritage",
		client: "Parks Canada",
		role: "Lead Product Designer - Mobile App",
		midTxt: [
			{
				title: "Step Outside",
				subtitle: "The trail markers that inspire this new concept",
				txt: "The new Parks Canada is inspired by the great outdoors, placing prominence on nature and Parks Canada's Heritage green. We use three core themes in our redesign process, that push a strong experience for all Canadians, and places emphasis on natural beatuy waiting at each National Park.",
			},
			{
				title: "The Great Outdoors",
				subtitle:
					"An easy to explore experience, accessible to all Canadians that inspires them to get outside",
			},
			{
				title: "Dark Park Mode",
				subtitle: "For when days of adventure turn into nights of discovery",
			},
		],
		order: [
			0,
			["01.jpg"],
			["02.jpg", "03.jpg"],
			1,
			["04.jpg"],
			["05.jpg"],
			2,
			["06.jpg"],
			["07.jpg"],
		],
		url: "/roots",
	},
	roots: {
		id: "RO",
		cover: "RO-landing-hero@2x.jpg",
		coverm: "RO-landing-hero-m@2x.jpg",
		title: "Roots",
		subtitle1: "Crafting a new online heritage experience",
		subtitle2: "for a Canadian Icon",
		client: "Roots Canada",
		role: "Product Designer - Ecommerce Website",
		midTxt: [
			{
				title: "Roots Redesign",
				subtitle: "An experience rooted in Canadiana",
				txt: "Founded in Toronto, Canada ion 1973, and inspired by the nearby wilderness of Algonquin Provincial Park, Roots inspire the world to experience everyday adventures with comfort and style. The most important aspect of this site redesign was to ensure Roots retained it's nostalgic, heritage feel, while creating a new customer-oriented, mobile first shopping experience.",
			},
			{
				title: "The Digital Outdoors",
				subtitle:
					"A Canadian brandat heart, Roots is all about landscapes and adventure, and the clothes built for them",
			},
			{
				title: "Sweatstyle",
				subtitle:
					"Bombastic colours, bold choices, and selling a Canadian heritage icon, Root's famous salt and pepper sweats",
			},
			{
				title: "Mobile",
				subtitle: "A digital cabin, made for adventures anywhere",
			},
		],
		order: [
			0,
			["01.jpg"],
			["02.jpg", "03.jpg"],
			1,
			["04.jpg"],
			["05.jpg"],
			["06.jpg"],
			["07.jpg", "08.jpg"],
			2,
			["09.jpg"],
			["10.jpg", "11.jpg"],
			3,
			["12.jpg"],
			["13.jpg"],
		],
		url: "/aeroplan",
	},

	srConfig: (delay = 200, viewFactor = 0.25) => ({
		origin: "bottom",
		distance: "20px",
		duration: 500,
		delay,
		rotate: { x: 0, y: 0, z: 0 },
		opacity: 0,
		scale: 1,
		easing: "cubic-bezier(0.645, 0.045, 0.355, 1)",
		mobile: true,
		reset: false,
		useDelay: "always",
		viewFactor,
		viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
	}),
};
