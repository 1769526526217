import React, { useState } from "react";
import styled from "styled-components";
import { BubblyLink as Link } from "react-bubbly-transitions";

const StyledSection = styled.section`
	padding: 200px calc(8.3333% + 16px) 140px 8.3333%;
	background-color: var(--black);
	color: var(--white);
	display: flex;
	flex-direction: column;
	gap: 261px;

	div {
		transition: var(--transition);
		transition-property: opacity;
	}

	&:has(.hover) div:not(.hover) {
		opacity: 0.4;
	}

    @media (max-width: 414px) {
        padding: 80px 8.53333%;
        gap: 120px;
    }
`;

const StyledProject = styled.div`
	position: relative;
	button {
		padding: 0;
		position: relative;
		text-align: start;
		z-index: 2;
		min-height: 236px;
		display: flex;
		align-items: flex-end;
		h1 {
			font-size: clamp(48px, 6.9444444vw, 100px);
			line-height: clamp(56.4px, 8.160vw, 117.5px);
			padding-bottom: 22px;
			z-index: 2;
		}
		&::after {
			content: "";
			width: 40px;
			height: 1px;
			background-color: var(--white);
			position: absolute;
			bottom: 0;
			left: 0;
			transition: var(--transition);
		}
		&:hover::after {
			width: 180px;
		}
	}
	p {
		margin-top: 44px;
		max-width: 272px;
		opacity: 0;
		transition: var(--transition);
		transition-duration: 0.8s;
		transform: translateY(12px);
		z-index: 2;
		position: relative;
	}
	img {
		position: absolute;
		top: -2.431vw;
		right: 0;
		z-index: 1;
		opacity: 0;
        width: clamp(300px, 52.083vw, 100%);
		transform: translateY(12px);
		transition: var(--transition);
		transition-duration: 0.8s;
	}
	&.hover p,
	&.hover img {
		opacity: 1;
		transform: translateY(0);
	}

	@media (max-width: 414px) {
		button {
            min-height: fit-content;
			h1 {
				// font-size: 48px;
				// line-height: 56.4px;
			}
		}
        p, img {
            display: none;
        }
	}
`;

const Projects = (props) => {
	const [hover, setHover] = useState(null);

	const projectData = [
		{
			name: ["Air Canada", "Aeroplan"],
			subtitle: "Product Design for Aeroplan, a new everyday loyalty program",
			img: "AC/AC-hero.jpg",
			link: "/aeroplan",
		},
		{
			name: ["Weston", "Smartorder"],
			subtitle: "Design for a smart order and stocking management app",
			img: "WF/Weston-hero.jpg",
			link: "/weston",
		},
		{
			name: ["Parks", "Canada"],
			subtitle: "Product Design for a national parks app",
			img: "PC/Parks-hero.jpg",
			link: "/parks",
		},
		{
			name: ["Roots"],
			subtitle: "Web design for Canada's most storied clothing retailer",
			img: "RO/Roots-hero.jpg",
			link: "/roots",
		},
	];
	return (
		<StyledSection ref={props.projectsRef}>
			{projectData?.map((project, i) => (
				<StyledProject key={i} className={i === hover ? "hover" : ""}>
					<Link to={project.link} colorStart="#1192E8">
						<h1
							onMouseEnter={() => setHover(i)}
							onMouseLeave={() => setHover(null)}>
							{project.name[0]}
							<br />
							{project.name[1]}
						</h1>
					</Link>
					<p>{project.subtitle}</p>
					<img
						src={process.env.PUBLIC_URL + `/assets/${project.img}`}
						alt={`${project.name} Hero`}
					/>
				</StyledProject>
			))}
		</StyledSection>
	);
};

export default Projects;
