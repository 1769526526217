import { css } from "styled-components";

const variables = css`
:root {
    --violet-degrade: linear-gradient(180deg, #0039AE, rgba(86,47,255,0));
    --black: #16161F;
    --grey: #DDE1E6;
    --violet: #0039AE;
    --light-blue: #EDF5FF;
    --blue: #1192E8;
    --navy: #0A1638;
    --white: #FFFFFF;

    --nav-height: 80px;
    --nav-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

    --transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}


`
export default variables