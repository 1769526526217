import { css } from "styled-components";

const mixins = {
	flexBetween: `
display: flex;
justify-content: space-between;
`,
	flexCenter: `
display: flex;
align-items: center;
justify-content: center;
`,
	link: `
display: inline-block;
text-decoration: none;
text-decoration-skip-ink: auto;
color: inherit;
position: relative;
&:hover,
&:active,
&:focus {
  outline: 0;
}`,
	arrowButton: `
border-radius: 20px;
font-family: 'Maven Pro';
font-weight: bold;
padding: 10px 20px;
text-decoration: none;
transition: all 100ms ease-in-out;
background-color: var(--blue);
color: var(--white);

.arrow-icon {
    overflow: visible;
	margin-left: 3px;
	width: 8px;

    .arrow-head {
        transform: translateX(0);
        transition: transform 100ms ease-in-out;
    }
    .arrow-body {
        opacity: 0;
	    transform: scaleX(1);
	    transition: transform 100ms ease-in-out, opacity 100ms ease-in-out;
    }
}

&:hover {
    background-color: var(--navy);
    .arrow-icon {
        .arrow-head {
            transform: translateX(3px);
        }
        .arrow-body {
            opacity: 1;
            transform: scaleX(2);
        }
    }
}`,
	background: css`
		position: absolute;
		padding: 0 8.3333%;
		width: 100%;
		left: 0;
		top: 0;
		height: 100%;
		display: flex;
		justify-content: space-between;
		div {
			width: 1px;
			height: 100%;
			background: linear-gradient(
				rgba(240, 242, 244, 0),
				rgba(221, 225, 230, 0.2)
			);
		}
	`,
	dropShadow: css`
		filter: drop-shadow(5px 8px 12px rgba(0, 0, 0, 0.1));
	`,
};

export default mixins;
