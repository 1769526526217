import React from "react";

const CanadaGovIcon = () => {
	return (
		<svg
			width="100px"
			height="24px"
			viewBox="0 0 100 24">
			<title>logo/colour/Canada</title>
			<g
				id="working"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd">
				<g
					id="home---default---desktop"
					transform="translate(-520.000000, -3773.000000)">
					<g
						id="clients---section"
						transform="translate(0.000000, 3560.000000)">
						<g
							id="section---content"
							transform="translate(136.000000, 80.000000)">
							<g
								id="logos---display"
								transform="translate(53.500000, 116.000000)">
								<g
									id="logo/colour/Canada"
									transform="translate(330.500000, 17.000000)">
									<path
										d="M20.0707826,8.07184113 L19.5769041,0.328205874 C19.5141736,0.142430541 19.2094824,0.140432957 19.2064953,0.328205874 C19.1756279,2.00118266 16.2362544,0.597879745 15.1917413,0.360167222 C6.11373741,-1.71532279 0,5.5938379 0,11.6654952 C0,19.5319818 5.1877153,23.8058133 11.857066,23.8058133 C17.1682509,23.8058133 19.8547108,20.3999322 20.7189981,16.7133905 C20.8275318,16.2519485 20.3087603,16.3468338 20.1942522,16.7133905 C18.5881515,21.8551723 14.6052607,23.153602 12.6297468,23.1246371 C8.33718622,23.062712 4.34533389,19.5929082 4.19995837,11.6654952 C4.01375823,1.56770689 15.3839158,-5.04130053 19.7003737,7.94799091 C19.7919802,8.2276527 20.0080521,8.28857902 20.0707826,8.07184113"
										id="Fill-1"
										fill="#000000"></path>
									<path
										d="M23.7758668,9.31134214 C26.1227852,8.31954157 32.4844576,8.62916713 32.4844576,11.5406461 L32.4844576,20.4918211 C32.4844576,22.9388617 34.8921151,23.7758495 35.5702022,22.1338353 C35.7882655,21.9170974 36.1576786,22.0719102 35.9724742,22.4434609 C35.5413263,24.1783628 31.2796331,24.456027 29.797002,22.1338353 C21.6151485,28.1106073 16.3965658,16.3418398 26.18452,16.1560645 C29.2423844,15.6916262 29.5809301,13.9257617 29.5809301,12.6862607 C29.5809301,9.6209677 27.9748294,9.34130591 27.0179399,9.34130591 C25.9993156,9.34130591 25.1658957,9.65093146 24.6092867,11.2310206 C23.7449994,14.3902 19.0202951,11.5716087 23.7758668,9.31134214 Z M25.875846,23.0936745 C27.8204924,23.341375 29.5500627,21.5765093 29.5500627,19.8416074 L29.5500627,15.6297011 C21.2437439,18.6959928 23.8376016,22.9698243 25.875846,23.0936745 L25.875846,23.0936745 Z"
										id="Fill-2"
										fill="#000000"></path>
									<path
										d="M40.2959022,9.00171659 L36.3120156,9.52708124 C36.0650764,9.58900635 36.0650764,9.71285657 36.5290832,9.83770559 C37.3933705,9.86866814 37.9489838,10.4569567 37.9489838,11.7274203 L37.9489838,21.0501459 C37.9489838,22.1647979 36.3906777,23.1436141 36.0650764,23.2794498 C35.3242587,23.5890754 36.4354853,23.5271503 36.4354853,23.5271503 L42.9504989,23.5271503 C43.1665707,23.5271503 43.8466494,23.5890754 42.982362,23.1865622 C41.9936094,22.8150115 40.9122545,22.0099851 40.9122545,21.0501459 C40.9122545,20.0903066 40.9122545,14.0496119 40.9122545,14.0496119 C40.9122545,11.1071704 42.828025,9.49711748 44.7109367,9.49711748 C46.6864505,9.49711748 48.3244144,9.93059325 48.3244144,14.0186494 L48.3244144,21.0501459 C48.3244144,22.0719102 46.9961203,23.0007869 46.28617,23.2494861 C45.8231589,23.3733363 45.6379545,23.5271503 46.0392308,23.5271503 L52.9853924,23.5271503 C53.3577926,23.5591116 53.5121297,23.4342626 53.1715925,23.341375 C51.503757,22.6911613 51.2876851,21.4526591 51.2876851,21.0501459 L51.2876851,12.8730348 C51.2876851,8.19569135 46.8109159,8.81494246 45.2356825,8.81494246 C43.2601687,8.84590502 41.1601895,10.0844072 40.9441177,10.642732 C40.8823829,9.12556681 40.6971784,8.96975524 40.2959022,9.00171659"
										id="Fill-3"
										fill="#000000"></path>
									<path
										d="M79.2007798,22.3815357 C78.5207011,23.5591116 76.8538613,23.8367758 74.7847496,23.8367758 C70.8635935,23.8367758 67.6513921,20.8014466 67.6513921,16.6514654 C67.6513921,12.9659225 69.7205039,8.81494246 75.0008214,8.81494246 C79.7553973,8.81494246 79.7265213,12.0050845 79.7265213,9.34130591 L79.7265213,4.66496123 C79.7265213,2.24888311 78.7676404,1.53674433 78.0268227,1.32000644 C76.9464635,0.948455777 75.5564346,0.886530666 77.7798835,0.886530666 L81.918107,0.793642999 C81.6711678,0.85556811 82.1959137,0.85556811 82.1959137,1.22711878 L82.1959137,19.5010193 C82.1959137,20.7704841 82.6589247,22.7221239 84.6971691,23.2794498 C84.8823735,23.3104124 85.0974496,23.4342626 84.8823735,23.4961877 L80.3737411,23.9925874 C79.7265213,24.0545125 79.447719,23.7438882 79.1699123,22.4744234 M75.1860258,23.0926757 C77.6464566,23.0926757 79.2923862,22.1338353 79.2923862,16.3728024 C79.2923862,10.8904325 77.6464566,9.56004138 75.1860258,9.56004138 C72.7265907,9.56004138 70.7321581,12.7571749 70.7321581,16.4347275 C70.7321581,20.1112813 72.7265907,23.0926757 75.1860258,23.0926757"
										id="Fill-4"
										fill="#000000"></path>
									<path
										d="M56.3827982,9.29536147 C58.7297166,8.30455969 65.0903933,8.61418525 65.0903933,11.5246655 L65.0903933,20.4768392 C65.0903933,22.9228811 67.5000422,23.7588701 68.1781294,22.1178546 C68.3942012,21.9011167 68.7656058,22.0559295 68.5794056,22.4274802 C68.147262,24.1623821 63.8865645,24.4410451 62.4039334,22.1178546 C54.22208,28.0956254 49.0025015,16.3258592 58.7904557,16.1400838 C61.8483202,15.6756455 62.1888573,13.9107798 62.1888573,12.6712788 C62.1888573,9.60498702 60.5817609,9.32632402 59.6248714,9.32632402 C58.606247,9.32632402 57.7728271,9.63594958 57.2162181,11.2150399 C56.3519308,14.3742194 51.6282223,11.5566268 56.3827982,9.29536147 Z M58.4827774,23.0786926 C60.4284196,23.3263931 62.1569942,21.5605286 62.1569942,19.8256267 L62.1569942,15.6137204 C53.8516711,18.6800122 56.444533,22.9548424 58.4827774,23.0786926 L58.4827774,23.0786926 Z"
										id="Fill-5"
										fill="#000000"></path>
									<path
										d="M87.7540378,9.32632402 C90.0999605,8.33552225 96.4606371,8.6451478 96.4606371,11.5566268 L96.4606371,20.5078017 C96.4606371,22.9548424 98.8682946,23.7908314 99.5483732,22.149816 C99.7654408,21.9320793 100.134854,22.0868921 99.9496495,22.4594415 C99.5185016,24.1933446 95.2578041,24.4720076 93.7741773,22.149816 C85.5913281,28.126588 80.3737411,16.3568217 90.1616953,16.1710464 C93.2195597,15.706608 93.5591012,13.9407436 93.5591012,12.7022414 C93.5591012,9.63594958 91.9530004,9.35628779 90.9961109,9.35628779 C89.9774866,9.35628779 89.143071,9.66691214 88.586462,11.2470013 C87.7221747,14.4061807 82.9984662,11.5865906 87.7540378,9.32632402 Z M89.854017,23.1096552 C91.7986634,23.3573556 93.527238,21.5914912 93.527238,19.8575881 L93.527238,15.6446829 C85.221915,18.7109747 87.8157726,22.985805 89.854017,23.1096552 L89.854017,23.1096552 Z"
										id="Fill-6"
										fill="#000000"></path>
									<polygon
										id="Fill-7"
										fill="#DA1E28"
										points="84.1415558 7.73125302 87.537966 7.73125302 87.537966 0.793642999 84.1415558 0.793642999"></polygon>
									<polygon
										id="Fill-8"
										fill="#DA1E28"
										points="95.071604 7.73125302 98.4680141 7.73125302 98.4680141 0.793642999 95.071604 0.793642999"></polygon>
									<path
										d="M90.8328124,2.23589881 L91.3197208,1.24509703 L91.8006549,2.1989435 C91.8603982,2.3008203 91.9091886,2.29282996 92.0057737,2.24388915 L92.4180029,2.03813797 L92.1491578,3.37152544 C92.0933973,3.63320898 92.2427558,3.71011597 92.4020714,3.53233097 L92.992535,2.90009557 L93.1488634,3.25866194 C93.2016367,3.36753028 93.2812945,3.3515496 93.3868411,3.33057497 L93.9962234,3.20173078 L93.7911046,3.97479588 L93.7871217,3.99077655 C93.76422,4.09165456 93.7154296,4.17655189 93.8279463,4.22449391 L94.0450139,4.33336225 L92.7884118,5.40107102 C92.6599636,5.53391037 92.7037754,5.57386205 92.7525658,5.72268208 L92.8680696,6.08124845 L91.696104,5.86750694 C91.5517242,5.83155042 91.4511562,5.83155042 91.4471733,5.9484091 L91.4959637,7.29677845 L91.1424822,7.29677845 L91.1912726,5.95240427 C91.1912726,5.81856612 91.0897089,5.82356008 90.8537226,5.8715021 L89.7693806,6.08124845 L89.9087817,5.72268208 C89.9575721,5.58584756 89.9695208,5.49295989 89.8619828,5.40107102 L88.5775005,4.34934292 L88.8144825,4.20451807 C88.8821916,4.15258088 88.8861745,4.09564973 88.8503285,3.97879105 L88.6093636,3.19374045 L89.2277074,3.3265798 C89.4009632,3.36753028 89.4487578,3.3265798 89.4915739,3.23069576 L89.6648297,2.87612456 L90.2752077,3.56928628 C90.3837415,3.69713167 90.536087,3.61223434 90.4872966,3.4274578 L90.1955499,1.98220561 L90.6486037,2.24388915 C90.7212915,2.287836 90.7969664,2.3008203 90.8407782,2.21592297"
										id="Fill-9"
										fill="#DA1E28"></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default CanadaGovIcon;
