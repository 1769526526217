import AirCanadaIcon from "./AirCanada"
import AnimatedArrowIcon from "./AnimatedArrow"
import ArrowIcon from './Arrow'
import CanadaGovIcon from "./CanadaGov"
import IBMIcon from "./IBM"
import LogoIcon from "./Logo"
import RBCIcon from "./RBC"
import RootsIcon from "./Roots"
import SmartOrderIcon from "./SmartOrder"
import TangerineIcon from "./Tangerine"
import TMLIcon from "./TML"
import WestonIcon from "./Weston"

const Icon = ({name}) => {
    switch (name) {
        case 'ac-logo':
            return <AirCanadaIcon/>
        case 'can-logo':
            return <CanadaGovIcon/>
        case 'IBM':
            return <IBMIcon/>
        case 'TML':
            return <TMLIcon/>
        case 'RBC':
            return <RBCIcon/>
        case 'roots':
            return <RootsIcon/>
        case 'smartorder':
            return <SmartOrderIcon/>
        case 'tangerine':
            return <TangerineIcon/>
        case 'weston':
            return <WestonIcon/>
        case 'logo':
            return <LogoIcon/>
        case 'arrow':
            return <ArrowIcon/>
        case 'next-arrow':
            return <AnimatedArrowIcon/>
        default:
            return null
    }
}

export default Icon