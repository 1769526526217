import { createGlobalStyle } from "styled-components";
import variables from "./variables";


const GlobalStyle = createGlobalStyle`
${variables}

html {
    box-sizing: border-box;
    width: 100%;
    scroll-behavior: smooth;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}
::-webkit-scrollbar {
    display: none;
}
body {
    font-family: 'Maven Pro';
    overflow-x: hidden;
}
h1,h2,h3,h4,h5,h6 {
    font-family: 'Maven Pro';
    margin: 0;
}

h1 {
    font-size: clamp(28px, 3.333vw, 48px);
    line-height: clamp(32.9px, 3.917vw, 56.4px);
    font-weight: 700;
}
h2 {
    font-size: clamp(20px, 1.944vw, 28px);
    line-height: clamp(23.5px, 2.500vw, 36px);
    font-weight: 400;
}
h3 {
    font-size: clamp(18px, 1.528vw, 22px);
    line-height: 24px;
    font-weight: 400;
}
p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
}
p.bold {
    font-weight: 700;
}

@media (max-width: 414px) {
    h1 {
        font-size: 28px;
        line-height: 32.9px;
    }
    h2 {
        font-size: 20px;
        line-height: 23.5px;
    }
    h3 {
        font-size: 18px;
        line-height: 24px;
    }
}


@keyframes fadein {
    0% {opacity: 0};
    100% {opacity: 1};
}
@keyframes fadeDown {
    0% {opacity: 0; transform: translateY(-20px)};
    100% {opacity: 1; transform: translateY(0)};
}
@keyframes fadeDown-delay-1 {
    0% {opacity: 0; transform: translateY(-20px)};
    50% {opacity: 0; transform: translateY(-20px)};
    100% {opacity: 1; transform: translateY(0)};
}
@keyframes fadeUp {
    0% {opacity: 0; transform: translateY(200px)};
    10% {opacity: 0; transform: translateY(200px)};
    100% {opacity: 1; transform: translateY(0)};
}

`

export default GlobalStyle