import React, { useEffect } from "react";
import styled from "styled-components";

import { Gradient } from "../../utils/gradient";
import Icon from "../Icons/Icon";

const StyledSection = styled.section`
background-color; #605CCF;
height: 100vh;
${({theme}) => theme.mixins.flexCenter};

#gradient-canvas {
	width: 100vw;
	height: 100vh;
	max-width: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	z-index: -1;
	--gradient-color-1: #605ccf;
	--gradient-color-2: #338bff;
	--gradient-color-3: #40c7df;
	--gradient-color-4: #3b22c8;
}
`;

const StyledContent = styled.div`
color: var(--white);
position: relative;
margin: auto;
top: 0;
bottom: 0;
padding: 0 calc(8.3333% + 16px) 0 8.3333%;
display: flex;
flex-direction: column;
gap: 24px;

.arrow {
	width: fit-content;
	cursor: pointer;
	border: 1px solid var(--blue);
	border-radius: 50%;
	padding: 8px;
	position: relative;
	width: 42px;
	height: 42px;
	svg {
		fill: var(--white);
		position: absolute;
		margin: auto;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	&:hover {
		background-color: rgba(17,146,232,0.4);
	}
}
`

const Hero = (props) => {
	const gradient = new Gradient();
	const canvasId = "gradient-canvas";

	useEffect(() => {
		gradient.initGradient("#" + canvasId);
	}, []);

	return (
		<StyledSection className="hero">
			<canvas id="gradient-canvas" data-transition-in></canvas>
			<StyledContent>
				<h1>
					Hi I'm Liam - a lead product designer & managing consultant at IBM iX
				</h1>
				<div
					className="arrow"
					onClick={() =>
						props.projectsRef.current?.scrollIntoView({ behavior: "smooth" })
					}>
					<Icon name="arrow" />
				</div>
			</StyledContent>
		</StyledSection>
	);
};

export default Hero;
