import React from "react";
import styled from "styled-components";
import { BubblyLink as Link } from "react-bubbly-transitions";
import Icon from "../Icons/Icon";

const StyledSection = styled.section`
	background-color: var(--light-blue);
	padding: 40px 8.3333%;
  position: relative;
	h1 {
		margin: 4px 0 16px 0;
	}

	div.container {
		margin-left: 16px;
    position: relative;
    z-index: 2;
	}

	button {
		${({ theme }) => theme.mixins.arrowButton};
	}
`;

const StyledBackground = styled.div`
${({theme}) => theme.mixins.background};
div {
  background: rgba(221, 225, 230, 0.3);
}


`

const ProjectNext = ({ activeProject, width }) => {
	return (
		<StyledSection>
      <StyledBackground>
        <div></div>
        <div></div>
        <div></div>
        {width > 414 ? <><div></div>
        <div></div></> : ''}
      </StyledBackground>

			<div className="container">
				<p>Next Project</p>
				<h1>
					{activeProject
						? activeProject.id === "AC"
							? "Weston SmartOrder"
							: activeProject.id === "WF"
							? "Parks Canada"
							: activeProject.id === "PC"
							? "Roots"
							: activeProject.id === "RO"
							? "Air Canada Aeroplan"
							: ""
						: ""}
				</h1>
				<Link to={activeProject?.url} colorStart='#1192E8'>
					View{" "}
					<span>
						<Icon name="next-arrow" />
					</span>
				</Link>
			</div>
		</StyledSection>
	);
};

export default ProjectNext;
