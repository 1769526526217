import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import { socialMedia } from "../config";

const StyledFooter = styled.footer`
	background-color: var(--grey);
	padding: 60px 8.333%;

	.container {
		padding-left: 16px;

		.socials {
			padding-top: 25px;
			display: grid;
			grid-template-rows: 1fr 1fr;
			grid-template-columns: 286px 286px;
			row-gap: 16px;
			a {
				color: #000;
				text-decoration: none;
				width: fit-content;
				trnasform: translate(0, 0);
				position: relative;

				&::after {
					content: "";
					width: 0;
					height: 1px;
					background-color: #000;
					position: absolute;
					bottom: -1px;
					left: 0;
					transition: var(--nav-transition);
				}
				&:hover::after {
					width: 100%;
				}
			}
		}
	}
	${(props) =>
		props.isHome
			? css`
					@media (max-width: 500px) {
						padding: 40px;
						.container {
							padding-left: 0;
							.socials {
								display: flex;
								flex-direction: column;
							}
						}
					}
			  `
			: css`
					@media (max-width: 414px) {
						padding: 40px;
						.container {
							padding-left: 0;
							.socials {
								display: flex;
								flex-direction: column;
							}
						}
					}
			  `}
`;

const Connect = ({ location }) => {
	const isHome = location.pathname === "/";

	const handleExternalLinks = () => {
		const allLinks = Array.from(document.querySelectorAll("a"));
		if (allLinks.length > 0) {
			allLinks.forEach((link) => {
				if (link.host !== window.location.host) {
					link.setAttribute("rel", "noopener noreferrer");
					link.setAttribute("target", "_blank");
				}
			});
		}
	};

	useEffect(() => {
		handleExternalLinks();
	}, []);

	return (
		<StyledFooter id="connect" isHome={isHome}>
			<div className="container">
				<h1>Let's Talk</h1>
				<div className="socials">
					{socialMedia?.map(({ url, name }, i) => (
						<a href={url} key={i}>
							{name}
						</a>
					))}
				</div>
			</div>
		</StyledFooter>
	);
};

export default Connect;
