import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Icon from "../Icons/Icon";
import { Fade } from "react-awesome-reveal";
import sr from "../../utils/sr";
import { srConfig } from "../../config";

const StyledSection = styled.section`
	padding: 40px 8.3333% 80px;
	display: flex;
	flex-direction: column;
	gap: 40px;

	.text-container {
		padding-left: 16px;
		display: flex;
		flex-direction: column;
		gap: 8px;

		h3 {
			position: relative;
			padding-bottom: 16px;

			&::after {
				content: "";
				width: 60px;
				height: 1px;
				background-color: rgba(221, 225, 230, 1);
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
		p:not(.bold) {
			padding-top: 16px;
			max-width: 568px;
			line-height: 40px;
		}

		&:not(:first-of-type) {
			margin-top: 40px;
		}
	}
	.img-group {
		display: flex;
	}
	img {
		width: 100%;
	}

	@media (max-width: 414px) {
		padding: 40px 0;

		.text-container {
			padding: 0 32px;
		}

		.img-group {
			flex-direction: column;
		}
	}

	// AC IMG GROUPS
	.AC-group:first-of-type {
		justify-content: space-between;
		padding: 0 16px;
		img: first-of-type {
			padding-bottom: 11%;
			width: calc(50% - 16px);
		}
		img:last-of-type {
			padding-top: 11%;
			width: calc(50% - 16px);
		}
		img {
			${({ theme }) => theme.mixins.dropShadow};
		}
	}
	.AC-group:nth-of-type(2) {
		flex-direction: column;
	}
	.AC-group:nth-of-type(3) {
		justify-content: space-between;
		padding: 0 16px;
		img {
			${({ theme }) => theme.mixins.dropShadow};
			width: calc(50% - 16px);
		}
	}
	.AC-group:last-of-type {
		justify-content: space-between;
		padding: 0 28.68% 0 16px;
		img {
			${({ theme }) => theme.mixins.dropShadow};
			width: calc(33% - 16px);
		}
	}
	.AC-img:not(span img, .AC-04, .AC-05),
	.AC-group:not(:nth-of-type(2), :last-of-type) {
		margin-bottom: 40px;
	}

	@media (max-width: 414px) {
		.AC-group:first-of-type {
			justify-content: center;
			align-items: center;
			gap: 24px;

			img:first-of-type,
			img:last-of-type {
				padding: 0 32px;
				width: 100%;
			}
		}
		.AC-group:nth-of-type(3) {
			padding: 0 32px;
			gap: 24px;
			img {
				width: 100%;
			}
		}
		.AC-group:last-of-type {
			padding: 0 32px;
			gap: 24px;
			img {
				width: 100%;
			}
		}
		.AC-img:not(span img, .AC-04, .AC-05),
		.AC-group:not(:nth-of-type(2), :last-of-type) {
			margin-bottom: 0;
		}

		.AC-05 {
			padding: 0 32px;
		}
	}

	// WF IMG GROUPS
	svg {
		margin: auto;
		width: 100%;
	}
	.WF-group:first-of-type {
		display: grid;
		grid-template-rows: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		gap: 32px;

		img {
			width: 100%;
		}
	}
	.WF-group:not(:first-of-type) {
		justify-content: space-between;

		img {
			width: calc(50% - 16px);
		}
	}

	@media (max-width: 414px) {
		svg {
			padding: 0 32px;
		}

		.WF-group {
			&:first-of-type {
				display: flex;
				padding: 0 32px;
				gap: 24px;
			}
			&:not(:first-of-type) {
				padding: 0 32px;
				gap: 24px;

				img {
					width: 100%;
				}
			}
		}
	}

	// PC IMG GROUPS
	.PC-group {
		justify-content: space-between;

		img {
			width: calc(50% - 16px);
		}
	}
	@media (max-width: 414px) {
		.PC-group {
			padding: 0 32px;
			gap: 24px;
			img {
				width: 100%;
			}
		}
	}

	// RO IMG GROUPS
	.RO-group {
		justify-content: space-between;

		img {
			width: calc(50% - 16px);
		}
	}
	.RO-group:last-of-type img,
	.RO-09 {
		${({ theme }) => theme.mixins.boxShadow};
	}
	@media (max-width: 414px) {
		.RO-group {
			padding: 0 32px;
			gap: 24px;

			img {
				width: 100%;
			}
		}
	}
`;

const ProjectInfo = ({ activeProject, width }) => {
	const ref = useRef([])

	useEffect(() => {
		ref.current?.forEach((ref) => sr.reveal(ref, srConfig()))
	})

	return (
		<StyledSection>
			{activeProject ? (
				activeProject.order.map((order, i) =>
					typeof order === "number" ? (
						<div key={i} className="text-container" ref={el => (ref.current[i] = el)}>
							<p className="bold">{activeProject.midTxt[order].title}</p>
							<h3 className="subtitle">
								{activeProject.midTxt[order].subtitle}
							</h3>
							<p>{activeProject.midTxt[order].txt}</p>
						</div>
					) : typeof order === "object" ? (
						order.length > 1 ? (
							<span key={i} className={`${activeProject.id}-group img-group`} ref={el => (ref.current[i] = el)}>
								{order.map((order, i) => (
									<img
										key={i}
										className={`${activeProject.id}-img ${
											activeProject.id
										}-${order.substring(0, order.length - 4)}`}
										src={
											process.env.PUBLIC_URL +
											`/assets/${activeProject.id}/${activeProject.id}-${
												width > 414
													? order
													: order.slice(0, -4) + "-m@2x" + order.slice(-4)
											}`
										}
										alt=""
									/>
								))}
							</span>
						) : (
							<div ref={el => (ref.current[i] = el)}>
							{order.map((order, i) => (
								<img
									key={i}
									className={`${activeProject.id}-img ${
										activeProject.id
									}-${order.substring(0, order.length - 4)}`}
									src={
										process.env.PUBLIC_URL +
										`/assets/${activeProject.id}/${activeProject.id}-${
											width > 414
												? order
												: order.slice(0, -4) + "-m@2x" + order.slice(-4)
										}`
									}
									alt=""
								/>
							))}
							</div>
						)
					) : (
						<Icon key={i} name={order} ref={el => (ref.current[i] = el)}/>
					)
				)
			) : (
				<></>
			)}
		</StyledSection>
	);
};

export default ProjectInfo;
