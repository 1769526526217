import React from "react";

const TangerineIcon = () => {
	return (
		<svg
			width="136px"
			height="28px"
			viewBox="0 0 136 28"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg">
			<title>logo/colour/Tangerine</title>
			<defs>
				<polygon
					id="path-1"
					points="5.2950034e-15 0 92.5314086 0 92.5314086 27.5815182 5.2950034e-15 27.5815182"></polygon>
			</defs>
			<g
				id="working"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd">
				<g
					id="home---default---desktop"
					transform="translate(-802.000000, -3890.000000)">
					<g
						id="clients---section"
						transform="translate(0.000000, 3560.000000)">
						<g
							id="section---content"
							transform="translate(136.000000, 80.000000)">
							<g
								id="logos---display"
								transform="translate(53.500000, 116.000000)">
								<g
									id="logos---row---02"
									transform="translate(40.500000, 117.000000)">
									<g
										id="logo/colour/Tangerine"
										transform="translate(572.000000, 17.000000)">
										<path
											d="M0,0.436764459 C5.6572274,0.436764459 11.3140822,0.436764459 17.0376329,0.436764459 C17.0376329,1.69599857 17.0376329,2.87671661 17.0376329,4.15083528 C14.8243726,4.15083528 12.6468822,4.15083528 10.4276603,4.15083528 C10.1724274,5.19573167 10.3345096,6.17588021 10.3054466,7.14300475 C10.2752658,8.12761866 10.2987397,9.11372102 10.2987397,10.0990792 C10.2987397,11.0844373 10.2987397,12.0701675 10.2987397,13.0555257 C10.2987397,14.0044166 10.2987397,14.9533076 10.2987397,15.9021985 C10.2987397,16.8875567 10.2987397,17.8729148 10.2987397,18.8582729 C10.2987397,19.8343282 10.2987397,20.8100114 10.2987397,21.8366742 C8.88918356,21.8366742 7.59103562,21.8366742 6.2291726,21.8366742 C6.0656,20.6600494 6.17849863,19.4997976 6.15837808,18.3458718 C6.13788493,17.1785499 6.15390685,16.0101116 6.15390685,14.8424176 C6.15390685,13.6743514 6.15390685,12.5066574 6.15390685,11.3385912 C6.15390685,10.1708972 6.15390685,9.00283102 6.15390685,7.83476486 C6.15390685,6.67004773 6.15390685,5.5053306 6.15390685,4.2252581 C4.06286027,4.11101907 2.02882192,4.22228119 0,4.16088236 C0,2.91950973 0,1.67813709 0,0.436764459"
											id="Fill-1"
											fill="#D97539"></path>
										<g id="Group-5" transform="translate(43.468591, 0.418482)">
											<mask id="mask-2" fill="white">
												<use xlinkHref="#path-1"></use>
											</mask>
											<g id="Clip-3"></g>
											<path
												d="M92.5314086,1.33296179 C91.6472222,2.66215335 90.7511127,3.98353052 89.8814579,5.32239705 C88.215551,7.88737953 86.5682743,10.4646418 84.9019949,13.0292522 C84.7719565,13.2294495 84.5543565,13.3730856 84.3285592,13.5900281 C84.0297318,13.3849932 83.7767346,13.2112159 83.6634633,13.1334441 C83.781951,11.6632213 83.9358359,10.3682642 83.9801757,9.06958602 C84.0666195,6.53623324 82.9085702,4.60979855 80.9497976,3.08413074 C79.8103784,2.1962665 78.4839127,1.70061052 77.195825,1.20048917 C76.8925264,0.844748093 76.876877,0.518776143 77.1943346,0.100892009 C77.4130524,0.0755882506 77.6932496,0.0160499948 77.9741921,0.0156778807 C82.3220935,0.00972405512 86.6703675,0.0212595922 91.0186414,0 C91.6576551,-0.00329993835 92.1547072,0.164151406 92.5314086,0.675436178 C92.5314086,0.894611383 92.5314086,1.11378659 92.5314086,1.33296179"
												id="Fill-2"
												fill="#D97539"
												mask="url(#mask-2)"></path>
											<path
												d="M11.0245592,13.7288267 C10.9451949,13.230938 10.9444496,12.7040244 10.7723072,12.2407424 C10.2234633,10.7608446 9.09261403,9.92209942 7.53923321,9.91279657 C6.07080581,9.90386583 4.90940307,10.5584145 4.2331291,11.9865884 C3.83220855,12.833148 3.80985239,13.7001739 3.93951814,14.5437565 C4.22120581,16.3801396 5.62666334,17.4648522 7.30188526,17.5441125 C9.26326608,17.6367689 11.1404387,16.0642147 11.0245592,13.7288267 Z M11.0327565,8.45857467 C11.0968442,7.84235372 11.1437921,7.39246778 11.1959565,6.88936952 C12.3458086,6.88936952 13.4569099,6.88936952 14.6257647,6.88936952 C14.6451401,7.19040982 14.6745757,7.43674936 14.6745757,7.683461 C14.6760661,11.9549587 14.6734579,16.2264565 14.6727127,20.4975821 C14.6723401,21.9525482 14.2963839,23.2925311 13.5049757,24.5238567 C12.4274086,26.1991143 10.8490633,27.0479066 8.93723869,27.4006708 C7.7415565,27.6213344 6.56040581,27.6615228 5.3628606,27.4010429 C3.132088,26.9161782 1.39985787,25.7901609 0.40910718,23.6739481 C0.317446906,23.4778439 0.252241427,23.2675995 0.191134577,23.0592156 C0.140088002,22.8850662 0.112888002,22.7042187 0.052898961,22.4210399 C1.09767704,21.9983183 2.23187978,21.8271458 3.30385787,21.4870335 C4.75812636,24.5327874 6.46203869,24.3560332 8.58438389,23.9645692 C10.0457318,23.6947865 11.3006579,21.9008244 10.8744003,19.2353711 C10.8147839,19.2323942 10.7253592,19.2018808 10.6962962,19.2312778 C8.66114006,21.2678583 5.54655376,21.1841326 3.31466334,19.8549411 C0.818970194,18.3687174 -0.412854464,15.4092939 0.124066084,12.5257818 C0.334586632,11.394927 0.71911266,10.380544 1.34918389,9.41528002 C2.75836745,7.25813459 6.05888252,5.81061074 9.20700307,7.07393811 C9.60158937,7.2320866 9.93916745,7.54056919 10.2882962,7.7995606 C10.5085044,7.96254658 10.7015127,8.16237185 11.0327565,8.45857467 L11.0327565,8.45857467 Z"
												id="Fill-4"
												fill="#D97539"
												mask="url(#mask-2)"></path>
										</g>
										<path
											d="M23.1378849,14.5704022 C23.0216329,13.9340871 22.985863,13.2691192 22.7734795,12.6662943 C22.2272438,11.1164391 21.0013808,10.3502562 19.4301151,10.3331389 C18.0127342,10.3175101 16.8502137,10.9259167 16.1791562,12.3068321 C15.5580274,13.5846719 15.5028822,14.869954 15.9026849,16.2010061 C16.3419836,17.6634146 17.3431671,18.5430923 18.827989,18.7849665 C20.1868712,19.0067465 21.3069151,18.5103462 22.1888658,17.4312154 C22.8763178,16.5902375 23.0972712,15.6376254 23.1378849,14.5704022 Z M22.9694685,8.56373638 C23.0160438,8.08333708 23.0499507,7.73392194 23.0957808,7.25947646 C24.2940712,7.24831304 25.4741041,7.21631123 26.7111452,7.29408308 C26.7111452,12.156498 26.7111452,16.953793 26.7111452,21.81658 C25.5378192,21.9669141 24.3820055,21.8519309 23.1408658,21.901422 C23.0954082,21.3949747 23.0547945,20.9406234 23.0138082,20.4818067 C22.9120877,20.5030172 22.8181918,20.4937144 22.7895014,20.5324143 C20.9324493,23.026323 15.8389699,22.6303936 13.5929205,19.8138619 C11.0551233,16.6319143 11.2097534,11.4171073 14.2423671,8.67090524 C15.3821589,7.63903285 16.7097425,6.99825237 18.282126,7.01016002 C19.0068384,7.01574173 19.7345315,7.06002331 20.4547726,7.14114418 C21.4347178,7.25166207 22.1765699,7.85634748 22.9694685,8.56373638 L22.9694685,8.56373638 Z"
											id="Fill-6"
											fill="#D97539"></path>
										<path
											d="M66.6083288,9.9840959 C64.7073096,10.4417962 63.7880986,11.2615636 63.7109699,12.8798878 C65.8407671,12.8798878 67.9683288,12.8798878 70.088811,12.8798878 C70.1972384,12.0898896 70.170411,11.8405731 69.8678575,11.4115256 C69.1718356,10.424679 68.2254247,9.89292794 66.6083288,9.9840959 Z M63.6718466,15.5300844 C63.5854027,17.0732416 64.4241315,18.2312607 65.9063452,18.790176 C67.7708493,19.4927275 69.117063,18.9766052 70.8627068,16.8644856 C71.8143342,17.1472923 73.0327452,17.6529954 74.0868384,17.9663154 C73.7075288,19.3781163 72.6757918,20.2190942 71.5516493,20.9889983 C69.8671123,22.1429241 68.0272,22.5202478 65.9596274,22.1838566 C63.6565699,21.8095098 61.9224767,20.6481417 60.8046685,18.7146369 C59.4219397,16.3230596 59.2762521,13.7498906 60.4152986,11.1856523 C61.2849534,9.22833216 62.7161205,7.79718134 64.7956164,7.20365935 C67.6132384,6.39952078 70.205063,6.84977884 72.3028164,9.03632129 C72.7689425,9.52193019 73.0916164,10.1668039 73.3975233,10.7793037 C74.0052384,11.9953726 74.2481753,14.1275864 73.9765479,15.5300844 C70.5791562,15.5300844 67.1620164,15.5300844 63.6718466,15.5300844 L63.6718466,15.5300844 Z"
											id="Fill-7"
											fill="#D97539"></path>
										<path
											d="M110.389896,12.8828647 C112.648241,12.8828647 114.818279,12.8828647 116.994652,12.8828647 C116.750225,11.0036885 115.676756,9.94651237 113.825293,9.9208365 C112.235397,9.89850965 110.663386,10.8239574 110.389896,12.8828647 Z M117.56846,16.8566712 C118.553622,17.1584557 119.720986,17.5305698 120.773216,17.8528206 C119.969885,20.1714636 118.228712,21.4757235 115.974093,22.090456 C114.379726,22.5254574 112.803989,22.3639599 111.256942,21.8184406 C109.279167,21.1210988 107.892712,19.7539516 107.129995,17.8543091 C106.104219,15.2993737 106.173523,12.717274 107.606181,10.3178822 C108.651704,8.56745752 110.20583,7.4425566 112.262225,6.9859726 C113.793249,6.6454882 115.253479,6.85015096 116.647014,7.37111069 C118.822268,8.18380789 120.088745,9.86501939 120.602564,12.1047741 C120.739682,12.7027615 120.797063,13.3227036 120.844756,13.9359476 C120.883507,14.4342084 120.852581,14.9380509 120.852581,15.5237585 C117.318816,15.5237585 113.900186,15.5237585 110.500559,15.5237585 C110.209184,15.9877848 110.35897,16.3453864 110.490126,16.7301524 C111.14069,18.6338881 114.081271,20.0296881 116.455123,18.2468895 C116.901129,17.9119868 117.17909,17.3545599 117.56846,16.8566712 L117.56846,16.8566712 Z"
											id="Fill-8"
											fill="#D97539"></path>
										<path
											d="M104.876866,21.83593 C103.654729,21.83593 102.463518,21.83593 101.199649,21.83593 C101.175058,21.5415877 101.131463,21.2625021 101.13109,20.9837887 C101.125501,18.5014155 101.140405,16.0186702 101.116932,13.5366692 C101.112088,13.0361757 101.033468,12.5167045 100.879956,12.0411426 C100.534553,10.9728031 99.3455781,10.2259701 98.2352219,10.307463 C97.0529534,10.3937935 95.8822356,11.2935654 95.6627726,12.358556 C95.531989,12.9948711 95.4552329,13.6531409 95.4474082,14.3028521 C95.4216986,16.4198092 95.4388384,18.5375106 95.4395836,20.6548398 C95.4399562,21.0157905 95.4395836,21.3771133 95.4395836,21.7622514 C94.7849205,21.9974275 94.162674,21.8601174 93.5557041,21.8854211 C92.9785425,21.9092364 92.3995178,21.8906307 91.8432219,21.8906307 C91.6591562,21.0001617 91.6036384,8.94813025 91.7582685,7.40050771 C92.5407342,7.20254301 94.2114849,7.18616999 95.3632,7.37706452 C95.3915178,7.85709171 95.4209534,8.3531198 95.4578411,8.97455035 C95.6955616,8.74830497 95.8550356,8.61359967 95.9951342,8.461405 C96.8219397,7.55977254 97.8499507,7.05853485 99.066126,7.01090425 C99.791211,6.98262357 100.543496,6.95583136 101.244362,7.10802603 C103.741173,7.64907993 104.816504,9.96065271 104.846312,11.6422363 C104.894005,14.3062012 104.870904,16.9716544 104.876866,19.6367356 C104.878356,20.3240304 104.876866,21.0113251 104.876866,21.83593"
											id="Fill-9"
											fill="#D97539"></path>
										<path
											d="M32.4693479,21.8325809 C31.1354301,21.8325809 29.9792438,21.8325809 28.7727562,21.8325809 C28.7727562,16.9746314 28.7727562,12.1751037 28.7727562,7.31194455 C29.9635945,7.31194455 31.111211,7.31194455 32.3557041,7.31194455 C32.4037699,7.84964943 32.447737,8.34158426 32.5069808,9.00357525 C33.1709589,8.07031309 33.9407562,7.47753533 34.9002082,7.23082368 C37.8769315,6.46538498 40.6271123,7.23119579 41.5966247,10.3588148 C41.755726,10.8727044 41.8812932,11.4212005 41.886137,11.9551843 C41.9159452,15.0574995 41.9058849,18.1601869 41.9058849,21.2625021 C41.9058849,21.4362794 41.8734685,21.6096846 41.8511123,21.8340694 C40.6416438,21.8340694 39.4862027,21.8340694 38.245063,21.8340694 C38.2182356,21.565403 38.1675616,21.2896665 38.1668164,21.014302 C38.1604822,18.6052353 38.1709151,16.1961687 38.1552658,13.787102 C38.1526575,13.3569381 38.0568986,12.9264021 37.9980274,12.4969824 C37.7718575,10.8555871 36.5251288,10.3770484 35.5079233,10.3364879 C34.2380932,10.2858804 33.1754301,10.8890774 32.7312877,12.1985469 C32.5595178,12.70425 32.4846247,13.2642817 32.478663,13.8008702 C32.4525808,16.1001632 32.4689753,18.3998284 32.4693479,20.6994935 C32.4697205,21.0559788 32.4693479,21.412092 32.4693479,21.8325809"
											id="Fill-10"
											fill="#D97539"></path>
										<path
											d="M79.4094685,21.8303482 C78.0759233,21.8303482 76.9204822,21.8303482 75.7154849,21.8303482 C75.7154849,16.970166 75.7154849,12.1706383 75.7154849,7.30822341 C76.9182466,7.30822341 78.0718247,7.30822341 79.3483616,7.30822341 C79.3483616,7.9095598 79.3483616,8.51052407 79.3483616,9.30647612 C79.9564493,8.74458383 80.3551342,8.2634403 80.8570301,7.94379429 C81.3656329,7.6193108 81.9640329,7.4161365 82.5456658,7.23045156 C83.1272986,7.04476663 83.7372493,6.87098935 84.3885589,7.22933522 C84.3885589,8.41675131 84.3885589,9.60788854 84.3885589,10.881263 C84.0655123,10.8998687 83.7484274,10.9504762 83.4354411,10.9311263 C80.8220055,10.7681403 79.4910685,12.4329788 79.4232548,14.896002 C79.3699726,16.8295069 79.4105863,18.7652444 79.4098411,20.6998656 C79.4094685,21.0563509 79.4094685,21.4124641 79.4094685,21.8303482"
											id="Fill-11"
											fill="#D97539"></path>
										<g
											id="Group-15"
											transform="translate(85.551121, 0.000000)"
											fill="#D97539">
											<path
												d="M0.501854588,7.3078513 C1.77280253,7.3078513 2.92936144,7.3078513 4.13249568,7.3078513 C4.13249568,12.1713826 4.13249568,16.9709102 4.13249568,21.8307204 C2.924145,21.8307204 1.76758609,21.8307204 0.595005273,21.8307204 C0.583454588,21.819929 0.554764177,21.7964859 0.531290205,21.7689494 C0.508561437,21.7425293 0.47353678,21.712016 0.473164177,21.6833632 C0.459377876,17.0475657 0.447454588,12.4117683 0.438881896,7.77634295 C0.438512122,7.63754439 0.475027191,7.49874583 0.501854588,7.3078513"
												id="Fill-12"></path>
											<path
												d="M3.99649568,0.632496476 C4.66270938,1.51291843 4.91533404,2.50757942 4.33407377,3.54280084 C3.72412308,4.62974613 2.6800902,4.73170539 1.52576692,4.54639257 C-0.0551865078,3.98561662 -0.326813905,2.08857894 0.340890205,1.05893923 C0.960528561,0.103722341 2.58768473,-0.54003505 3.99649568,0.632496476"
												id="Fill-14"></path>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default TangerineIcon;
